.nostum_container{
  max-width: 1440px;
  margin: 57px 130px;

 
}

.footer{
  font-size: 20px;
  font-family: Helvetica;
  width: 100%;
  text-align: center;

}
@media screen and (min-width: 1920px) {
  .nostum_container{
  margin: 57px auto;
  }
}
/*mediaquery for tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nostum_container{
  margin: 57px 50px;
  }
}
@media screen and (max-width:767px) {
  .nostum_container{
  margin: 57px 15px;
  }


  .footer{
    font-size: 18px;
    line-height: 22px;
  
  }
}
h1 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}