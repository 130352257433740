.title {
  font-weight: bold;
  font-size: 100px;
  max-width: 854px;
  line-height: 136px;
  margin: 0;
  margin-bottom: 111px;
  
}
.lambda {
  width: 70px !important;
}
.homepage {
  margin-top: 121px;
  margin-bottom: 57px;
  &_contact {
    background-color: transparent;
    border: none;

    display: flex;
    align-items: center;
    max-width: 240px;
    text-decoration: none;
    color: #000;
    font-size: 25px;
    margin-bottom: 186px;
    cursor: pointer;

    & .arrow {
      margin-right: 29px;
    }
  }

  &_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f4f4f5;
    border-radius: 10px;
    height: 360px;
    font-size: 50px;
    text-align: center;
    line-height: 68px;
    font-weight: bold;
    color: #000;

    & h3 {
      padding: 0;
      margin: 0;
      margin-bottom: 49px;
    }
    & a {
      text-decoration: none;
      font-size: 70px;
      color: #3072b4;
      line-height: 96px;
    }
  }

  &_stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
   
    width: 100%;
    > h2 {
      font-size: 65px;
      font-weight: bold;
      max-width: 751px;
      text-align: center;
    }

    &_container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      justify-items: center;
      align-items: center;
      gap: 37px;
    }

      & img {
        width: 120px;
        height: auto;
        transition: transform 0.3s ease;
        aspect-ratio: 1;
        &:hover {
          transform: scale(1.2);
          transition: transform 0.3s ease;
        }
      
    }
  }

  &_contact_container{
    margin-top: 180px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .homepage {
    &_banner {
      & p {
        padding: 10px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .homepage {
    &_banner {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 50px;
    line-height: 68px;
  }
  .homepage_contact {
    margin-bottom: 270px;
  }
  .homepage_banner {
    padding: 50px 31px;
    font-size: 30px;
    line-height: 41px;
    & p {
      margin: 0;
      padding: 0;
    }
    & h3 {
      font-size: 25px;
      line-height: 34px;
    }

    & a {
      font-size: 34px;
      line-height: 46px;
    }
  }
  .homepage_stack {
    margin-bottom: 115px;
    margin-top: 115px;
    > h2 {
      font-size: 40px;
      line-height: 55px;
      padding: 10px;
    }

    &_container {
      grid-template-columns: repeat(3, 1fr);
      gap: 0;
      row-gap: 30px;
     
    }
   
      & img {
        width: 100px;
        height: 100px;
    }
  }
  .vpc_icon {
    flex: 50% 0 !important;
  }
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
}
.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #1f2020;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


