body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(59, 56, 56);

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(30, 30, 30);
}

::selection {
  color: #fff;
  background: rgb(26, 25, 25);
}

body {
  animation: fadeInAnimation ease-in-out 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  overflow-x: hidden;
}
body img {
  animation: unset;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.fade-lazy {
  animation-name: fade;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in-section {
  opacity: 0;

  visibility: hidden;
  transition: opacity 0.8s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}

.slide-top {
  transform: translateY(15vh);
}

.slide-bottom {
  transform: translateY(-15vh);
}

.slide-left {
  transform: translateX(-16vw);
}

.slide-right {
  transform: translateX(100vw);
  
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section-bottom {
  opacity: 0;
  transform: translateY(-20vh);
  visibility: hidden;
  transition: opacity 1.2s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}