.header {
  width: 100%;
  

  > img {
    height: 48px;
    width: 153px;
    aspect-ratio: 153 / 48;
    margin-left: 6px;
    cursor: pointer;
  }
}
